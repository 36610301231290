@import './app-theme.scss';
@import './button.scss';

#background {
    height: 100%;
    position: fixed;
    background-color: #EAAFB0;
}

#background-sheer, #background-video {
    background-color: rgba(20, 20, 20, 0.8);
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: -99;
}

#background-video {
    z-index: -100;
    min-width: 100%;
    min-height: 100%;
    object-fit: cover;
}

#introduction-container {
    height: 100%;
    width: 100%;
    overflow: auto;
    position: fixed;
    background-color: #EAAFB0;

    display: flex;
    color: $primaryColor;
    font-family: 'Poppins', sans-serif;

    div {
        @media (max-width: 768px) {
            width: 360px;
        }
    }

    #text-description {
        flex: 3;
        text-align: left;
        padding-top: 10vh;
        padding-bottom: 10vh;
        padding-left: 10vw;
        padding-right: 10vw;

        h1 {
            font-size: 72px;
            font-weight: 600;
            letter-spacing: 0.2em;
        }
    
        p {
            font-size: 27px;
        }
    }

    #intro-image {
        display: flex;
        flex: 5;

        img {
            align-self: flex-end;
            max-width: 100%;
            width: auto;
            height: auto;

            @media (max-width: 768px) {
                display: none;
            }
        }
    }
}

#link-icon-row {
    display: inline-flex;
    padding-top: 5vh;
    padding-bottom: 5vh;
}

